<template>
  <div class="frame">
    <div class="error">
      <img class="bg" src="@/assets/error/404.png" alt="" />
      <div class="text">404 - 未找到当前页面</div>
      <button class="back" @click="$router.push('/')">返回首页</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.frame {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/error/bg.jpg");
  background-size: cover;
}
.error {
  text-align: center;
  letter-spacing: 1px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .bg {
    width: 400px;
  }
  .text {
    margin: 20px 0 30px;
    font-size: 42px;
    font-weight: 500;
    line-height: 1.2;
    color: #2c2c2c;
  }
  .back {
    background-color: #6a83fc;
    outline: none;
    border: none;
    padding: 15px 35px;
    font-size: 18px;
    border-radius: 8px;
    color: #eee;
    line-height: 1.15;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 2px;
    transition: all 0.3s;

    &:hover {
      box-shadow: 0 8px 25px -8px rgb(106, 131, 252);
    }
  }
}
</style>
